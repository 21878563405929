<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Room List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container" :value="term" placeholder="search" @input="search" removable>
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 mt-2 text-right">
            <va-button icon="fa fa-plus" class="my-0" @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table :fields="fields" :data="filteredData" :per-page="parseInt(perPage)" style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0">
            </va-button>
          </template>
          <template slot="action" slot-scope="props">
            <va-button flat small color="red" icon="fa fa-remove" @click="remove(props.rowData)" class="ma-0">
            </va-button>
          </template>
        </va-data-table>
        <va-modal v-model="showRemoveModal" title="Delete Room" size='small' :message="msg" okText="Confirm"
          cancelText="Cancel" @ok="deleteRoom(entity)" @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Room Number</span>
            <va-input 
            	v-model.trim="room_no" 
            	type="text" 
            	v-if="isCreate || isUpdate" 
            	placeholder="Enter your room number"
              :error="!!RoomNoErrors.length" 
              :error-messages="RoomNoErrors" 
           	/>
           	<span class="va-form-label va-form-required-label">Category</span>
            <va-input 
            	v-model.trim="category" 
            	type="text" 
            	v-if="isCreate || isUpdate" 
            	placeholder="Enter your category"
              :error="!!CategoryErrors.length" 
              :error-messages="CategoryErrors" 
           	/>
           	<span class="va-form-label">STB</span>
            <va-select
              v-if="isCreate || isUpdate"
              placeholder="Select Type"
              v-model="stb"
              textBy="serialAndMac"
              searchable
              :options="stbArr"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createRoom()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateRoom()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import vueResource from 'vue-resource'
  import { search } from '../../i18n/search.js'
  import config from '../../i18n/en.json'
  Vue.use(vueResource)

  export default {
    name: 'app_setting',
    beforeCreate() {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/room').then(response => {
        loader.hide()
        this.room_list = response.body
        this.isshowgrid = true
        this.getRoom()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      });
    },
    data() {
      return {
        term: null,
        perPage: '10',
        perPageOptions: ['4', '6', '10', '20'],
        room_list: [],
        room_no: '',
        RoomNoErrors: [],
        category: '',
        CategoryErrors:[],
        stb: '',
        stbArr: [],
        StbErrors: [],
        isshowgrid: true,
        isshowForm: false,
        isCreate: false,
        isUpdate: false,
        showRemoveModal: false,
        msg: ''
      }
    },
    computed: {
      formReady() {
        return !this.RoomNoErrors.length && 
        			 !this.CategoryErrors.length 
      },
      fields() {
        return [{
          name: 'room_no',
          title: 'Room No'
        },
        {
          name: 'category',
          title: 'Category'
        },
        {
        	name: 'stb',
        	title: 'STB'
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        },
        {
          name: '__slot:action',
          dataClass: 'text-right',
        }]
      },
      filteredData() {
        return search(this.term, this.room_list)
      },
    },
    methods: {
      getRoom() {
        const loader = Vue.$loading.show({ width: 40, height: 40 });
       var org_id = Vue.$cookies.get('org-id')
        this.$http.get(config.menu.host + '/room').then(response => {
          loader.hide();
          this.room_list = response.body;
          this.isshowgrid = true;
          this.$http.get(config.menu.host + '/emm/'+ org_id).then(res => {
            var arr = [];
            arr =this.room_list.map(function(data){return data.stb})
            var final = res.body.filter(function(item){
              return arr.indexOf(item.unique_id) ==  -1
            })
            this.stbArr = final
            this.stbArr.map(function (argument) {
              argument.serialAndMac = argument.unique_id + ' - ' + argument.mac_address
            })
          })
        }, error => {
          loader.hide();
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          };
        });
      },
      createRoom() {

        this.RoomNoErrors = this.room_no ? [] : ['Room Number is required'];
        this.CategoryErrors =  this.category ? [] : ['Category is required'];

        if (!this.formReady) { return };

				var payload = {
					room_no : this.room_no,
					category: this.category,
					stb     : this.stb.unique_id			
        } 

        const loader = Vue.$loading.show({ width: 40, height: 40 });
        this.$http.post(config.menu.host + '/room', payload).then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.list();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      updateRoom() {
        this.RoomNoErrors = this.room_no ? [] : ['Room Number is required'];
        this.CategoryErrors =  this.category ? [] : ['Category is required'];
        if (!this.formReady) { return };

        var payload = {
					room_no : this.room_no,
					category: this.category,
					stb     : this.stb.unique_id
				}

        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.put(config.menu.host + '/room/' + this.room_id, payload).then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.list();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      edit(row) {
        this.title = 'Update Room';
        this.room_id = row.room_id;
        this.room_no = row.room_no;
        this.category = row.category;
        if(row.stb){
          this.stb = row.stb;
        }else{
          this.stb = '';
        }
        this.isshowgrid = false;
        this.isshowForm = true;
        this.isCreate = false;
        this.isUpdate = true;
        this.RoomNoErrors = [];
        this.StbErrors = [];
        this.CategoryErrors = [];
      },
      remove(row) {
        this.msg = 'Are you sure to delete the room ' + row.room_no + ' ?';
        this.entity = row;
        this.showRemoveModal = true;
      },
      cancel() {
        this.showRemoveModal = false;
      },
      deleteRoom(data) {
        this.$http.delete(config.menu.host + '/room/' + data.room_id).then(resp => {
          Vue.notify({ text: resp.body, type: 'success' });
          this.list();
        }, err => {
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      add() {
        this.title = 'Create Room';
        this.room_no = '';
        this.RoomNoErrors = [];
        this.category = '';
        this.CategoryErrors = [];
        this.stb = '';
        this.StbErrors = [];
        this.isshowForm = true;
        this.isshowgrid = false;
        this.isUpdate = false;
        this.isCreate = true;
      },
      list() {
        this.getRoom();
        this.isshowgrid = true;
        this.isshowForm = false;
      },
      search: function (term) {
        this.term = term;
      },
    },
  }
</script>
